<div class="flex flex-row gap-2 items-center">
  <h3 class="grow">
    <fa-icon [icon]="faMoneyBillTrendUp"></fa-icon> Payout Detail
  </h3>
  @if (!hideAddPayoutButton) {
    @if (user$ | async; as user) {
      @if (user.type === UserType.AGENCY || user.type === UserType.EMPLOYEE) {
        <button type="button" class="btn btn-outline" routerLink="/payouts/add">
          <fa-icon [icon]="faPlus"></fa-icon>
          Add Payout
        </button>
      }
    }
  }
</div>

@if (filterData && (!hiddenKeyMap['agent-id'] || !hiddenKeyMap['agency-id'])) {
  <app-commission-statistics-quick-filter
    [data]="{ data: filterData }"
    [filter]="filter"
    [hiddenKeyMap]="hiddenKeyMap"
    (filterChanged)="
      filterChanged($event)
    "></app-commission-statistics-quick-filter>
}

@if (filterData) {
  <app-commission-statistics-filter
    [data]="{ data: filterData }"
    [filter]="filter"
    [hiddenKeyMap]="hiddenKeyMap"
    [updateRoute]="!isModal"
    [loadingChanged]="loadingChanged"
    (filterChanged)="filterChanged($event)"></app-commission-statistics-filter>
}

@if (!filterData || loading) {
  <app-loading></app-loading>
}

@if (filter['user-id'] && filter['user-id'][0]) {
  <app-payouts-table
    [data$]="commissions$"
    [filter]="filter"
    [userId]="filter['user-id'][0]"
    (filterChanged)="filterChanged($event)">
  </app-payouts-table>
}

<div infiniteScroll [fromRoot]="true" (scrolled)="onScroll()"></div>

@if (loadingNext) {
  <app-loading></app-loading>
}
